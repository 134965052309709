import * as React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import AppFooter from "./appFooter"
import logo from "../images/logo-square-white.svg"
import { CacheProvider, css } from "@emotion/react"
import { rhythm } from "../utils/typography"
import createCache from "@emotion/cache"
import { cacheOptions } from "./styled-components"


const navbarCss = css`
  background-color: #2196F3;
  color: #fff;
  position: relative;
  z-index: 30;
  
  @media screen and (min-width: 1024px) {
    align-items: stretch;
    display: flex;
    min-height: ${rhythm(2.25)};
  }
`

const navbarBrandCss = css`
  align-items: stretch;
  display: flex;
  flex-shrink: 0;
  min-height: ${rhythm(2.25)};
  color: #fff;
`

const navbarItemCss = css`
  text-decoration: none;
  color: #fff;
  line-height: ${rhythm(1.5)};
  padding: ${rhythm(0.25)} ${rhythm(0.5)};
  position: relative;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  display: flex;
  &:hover {
    background-color: #0d8aee;
  }
`

type Data = {
  site: {
    siteMetadata: {
      title: string;
      titleShort: string;
    }
  };
  siteBuildMetadata: {
    buildTime: string;
  }
}

type Props = {
  children: any
}

const Layout = ({ children }: Props) => {
  const data: Data = useStaticQuery(
    graphql`
        query {
            site {
                siteMetadata {
                    title,
                    titleShort
                }
            }
            siteBuildMetadata {
                buildTime(formatString: "MMM YYYY", locale: "de")
            }
        }
    `,
  )
  return (
    <CacheProvider value={createCache(cacheOptions)}>
      <div>
        <header>
          <nav css={navbarCss} role="navigation" aria-label="main navigation">
            <div css={navbarBrandCss}>
              <Link css={navbarItemCss}
                    to={`/`}>
                <img css={css`
                    width: 28px;
                    height: 28px;
                    margin: 0;
                  `} src={logo} alt={data.site.siteMetadata.titleShort}/>
                {data.site.siteMetadata.title}
              </Link>
            </div>
          </nav>
        </header>
        <main css={css`
          margin-top: ${rhythm(1)};
          min-height: ${rhythm(15)};
        `}>
          {children}
        </main>
        <AppFooter/>
      </div>
    </CacheProvider>
  )
}
export default React.memo(Layout)
